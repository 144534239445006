.my_menu_div {
  margin: 3vh 5vw auto 0;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  .my_menu_div {
    margin: 0.5vh 5vw auto 0;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .my_menu_div {
    margin: 0.5vh 0.5vw auto 0;
  }
}

/* Small Computer Styles */
@media only screen and (min-width: 1025px) and (max-width: 1600px) { 

}

/* Large Computer Styles */
@media only screen and (min-width: 1600px) {
  .my_menu_div {
    margin: 3vh 65px auto auto;
  }
}