.home_border {
  margin: 20px;
  border: 4px solid var(--blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.header_text_home {
  color: var(--dark-green);
  font-size: 3vmin;
  padding-left: 38%;
  margin-bottom: 0;
  margin-top: 0.5em;
}

.big_title {
  color: var(--dark-green);
  font-family: "Bebas Neue", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20vmin;
  text-align: center;
  margin-top: 3vh;
  margin-bottom: 0;
}

.date_holder {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
}

.home_text {
  color: var(--dark-green);
}

.div_line {
  border: none;
  color: var(--light-green);
  background-color: var(--light-green);
  width: 15vmin;
  height: 3px;
  margin: auto;
}

.rsvp_button {
  margin-bottom: 2em;
  border-color: var(--dark-green);
  background-color: var(--blue);
  padding-left: 5vmin;
  padding-right: 5vmin;
  border-style: solid;
}

.rsvp_button:hover {
  cursor: pointer;
  filter: brightness(85%);
}

.rsvp_text {
  color: var(--dark-green);
  font-size: 3vmin;
  padding-left: 0;
  margin: 0.2em;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  .home_border {
    margin-top: 10px;
    width: 95%;
  }
  .header_text_home {
    margin-left: 20vmin;
    margin-top: 2vmin;
    font-size: 4.5vmin;
    padding-left: 0;
  }
  .big_title {
    font-size: 23vmin;
    margin-top: 0;
  }
  .div_line {
    width: 5vmin;
  }
  .rsvp_text {
    font-size: 4.5vmin;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .header_text_home {
    margin-top: 1vmin;
    font-size: 4vmin;
    padding-left: 15vmin;
  }
  .big_title {
    font-size: 21.5vmin;
    margin-top: 0;
  }
  .rsvp_text {
    font-size: 39px;
  }
  .div_line {
    width: 8vmin;
  }
  .rsvp_text {
    font-size: 4vmin;
  }
}

/* Small Computer Styles */
@media only screen and (min-width: 1025px) and (max-width: 1600px) { 
 .big_title_holder {
    width: '100%';
    margin-top: "2%";
  }
}