.form_p {
  font-size: 3vw;
  margin: 0 auto 0.5em 0;
  color: var(--red);
}

.form_text_input {
  border-color: var(--red);
  color: var(--red);
  background-color: var(--white);
  border-style: solid;
  font-family: "Onest", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 2.5vw;
  margin-bottom: 0.6em;
  outline: none;
}

.form_text_input:active {
  outline: none;
}

.checkbox_holder {
  margin-bottom: 1em;
}

.checkbox {
  accent-color: var(--red);
  width: 2vw;
  height: 2vw;
}

.remove_guest_button {
  background-color: transparent;
  border: none;
  align-self: start;
}

.remove_guest_button:hover {
  cursor: pointer;
}

.remove_guest_p {
  color: var(--red);
  text-decoration: underline;
  text-decoration-thickness: 2px;
  margin: 0;
  font-size: 1.1em;
}

.add_guest_button {
  background-color: transparent;
  border: none;
  align-self: start;
}

.add_guest_button:hover {
  cursor: pointer;
}

.add_guest_p {
  color: var(--red);
  text-decoration: underline;
  text-decoration-thickness: 2px;
  margin: 0;
  margin-top: 20%;
}

.submit_rsvp_button {
  border-color: var(--red);
  background-color: var(--red);
  border-radius: 2em;
  border-style: solid;
  margin-top: 2em;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.submit_rsvp_button:hover {
  cursor: pointer;
  filter: brightness(85%);
}

.submit_rsvp_p {
  color: var(--white);
  margin: 0;
  font-size: 3vw;
  font-weight: 300;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  .form_p {
    font-size: 5.5vw;
  }
  .form_text_input {
    font-size: 5vw;
  }
  .add_guest_p {
    margin-top: 10%;
  }
  .submit_rsvp_button {
    margin-top: 1em;
    padding-left: 4em;
    padding-right: 4em;
  }
  .submit_rsvp_p {
    font-size: 6vw;
  }
  .checkbox {
    width: 4vw;
    height: 4vw;
  }
  .remove_guest_p {
    font-size: 1em;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .form_p {
    font-size: 3.5vw;
  }
  .form_text_input {
    font-size: 3vw;
  }
  .submit_rsvp_button {
    margin-top: 3em;
    padding-left: 4em;
    padding-right: 4em;
  }
  .submit_rsvp_p {
    font-size: 3vw;
  }
  .checkbox {
    width: 3vw;
    height: 3vw;
  }
  .remove_guest_p {
    font-size: 1.4em;
  }
  
}

/* Small Computer Styles */
@media only screen and (min-width: 1025px) and (max-width: 1600px) { 

}

/* Large Computer Styles */
@media only screen and (min-width: 1601px) {
  .form_p {
    font-size: 37px;
  }
  .form_text_input {
    font-size: 35px;
  }
  .submit_rsvp_p {
    font-size: 39px;
  }
  .checkbox {
    width: 32px;
    height: 32px;
  }
  .remove_guest_p {
    font-size: 20px;
  }
} 