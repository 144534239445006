.header_text_gallery {
  color: var(--purple);
}

img {
  max-width: 100%;
}

.gallery_holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.img_holder {
  width: 30%;
  padding: 1%;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  .gallery_holder {
    width: 90%;
  }
  .img_holder {
    width: 80%;
    padding-bottom: 3%;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .gallery_holder {
    width: 90%;
  }
  .img_holder {
    width: 80%;
    padding-bottom: 3%;
  }
}

/* Small Computer Styles */
@media only screen and (min-width: 1025px) and (max-width: 1600px) { 
  .gallery_holder {
    width: 80%;
  }
  .img_holder {
    width: 40%;
    padding-bottom: 3%;
  }
}

/* Large Computer Styles */
@media only screen and (min-width: 1601px) {
  
}