.header_text_rsvp {
  color: var(--red);
}

.rsvp_p {
  margin-top: 0.5em;
  color: var(--red);
}

.spacer {
  margin-top: 4em;
  margin-bottom: 4em;
}

.entry_div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0;
  margin-bottom: 1em;
  width: 100%
}

.entry_p {
  margin: auto 0 auto 0;
  color: var(--red);
}

.rsvp_text_input {
  border-color: var(--red);
  color: var(--red);
  background-color: var(--white);
  border-style: solid;
  font-family: "Onest", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 2vw;
  margin-left: 0.5em;
  margin-right: 0.5em;
  outline: none;
}

.rsvp_text_input:active {
  outline: none;
}

.start_rsvp_button {
  border-color: var(--red);
  background-color: var(--red);
  border-radius: 2em;
  border-style: solid;
  margin-top: 2em;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.start_rsvp_button:hover {
  cursor: pointer;
  filter: brightness(85%);
}

.start_rsvp_p {
  color: var(--white);
  margin: 0;
  font-size: 3vw;
  font-weight: 300;
}

.error_div {
  margin-bottom: 20vh;
}

.error_p {
  font-family: "Lucida Console", "Courier New", monospace;
  font-weight: bold;
  color: var(--red);
  font-size: 1.5em;
}


/* 
The following code is adapted from an online tutorial found at
https://www.youtube.com/watch?v=9DwGahSqcEc
with source code found at
https://github.com/ecole-du-web/react-modal/blob/main/src/Components/Modal/Modal.css
Thanks for saving me a ton of time
*/
body.active-modal {
  overflow-y: hidden;
}
.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 75% auto 0;
  font-size: 18px;
}
.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}
.overlay {
  background: rgba(49,49,49,0.8);
}
.modal-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 16px 14px 28px 16px;
  border-radius: 3px;
  width: 75%;
  max-height: 100%;
  margin-top: 4em;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  .spacer {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .entry_div {
    align-items: center;
  }
  .entry_p {
    margin-top: 0.25em;
  }
  .rsvp_text_input {
    font-size: 5vw;
  }
  .start_rsvp_button {
    margin-top: 1em;
    padding-left: 4em;
    padding-right: 4em;
  }
  .start_rsvp_p {
    font-size: 6vw;
  }
  .error_p {
    font-size: 1.25em;
  }
  .modal-content {
    padding: 14px 12px 28px 12px;
  }  
}

/* Tablet Styles */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .entry_div {
    align-items: center;
  }
  .entry_p {
    margin-top: 0.25em;
  }
  .rsvp_text_input {
    font-size: 4vw;
  }
  .start_rsvp_button {
    margin-top: 3em;
    padding-left: 4em;
    padding-right: 4em;
  }
  .start_rsvp_p {
    font-size: 3vw;
  }
}

/* Small Computer Styles */
@media only screen and (min-width: 1025px) and (max-width: 1600px) { 

}

/* Large Computer Styles */
@media only screen and (min-width: 1601px) {
  .rsvp_text_input {
    font-size: 39px;
  }
  .start_rsvp_p {
    font-size: 39px;
  }
}