/* General structural settings for webpage */
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 1%;
}

.App_holder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.header_div {
  display: flex;
  flex-direction: row;
  width: 80vw;
  justify-content: space-between;
  align-items: flex-start;
  height: 20%;
  margin-bottom: 0;
  margin-top: 0.5vh;
}

.body_div {
  display: flex;
  flex-direction: column;
  width: 60vw;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20vw;
  align-items: center;
  margin-bottom: 6em;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  .App {
    margin: 10px;
  }
  .header_div {
    width: 90vw;
    padding: 0 5vw;
    margin-bottom: 0;
    margin-top: 0.5vh;
    align-items: center;
  }
  .body_div {
    width: 80vw;
    padding: 0 10vw;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .header_div {
    margin-bottom: 3vw;
  }
}

/* Small Computer Styles */
@media only screen and (min-width: 1025px) and (max-width: 1600px) { 

}

/* Large Computer Styles */
@media only screen and (min-width: 1601px) {
  .App {
    align-items: center;
  }
  .App_holder {
    width: 75%;
  }
  .header_div {
    width: 100%;
  }
  .body_div {
    width: 80%;
  }
}