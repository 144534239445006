:root {
  --red: #611631;
  --purple: #E7D3E8;
  --blue: #A6C3DC;
  --light-green: #C1BE65;
  --dark-green: #14350A;
  --white: #FBF7F3;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-family: "Playwrite RO", serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  margin-top: 10px;
  font-size: 6vw;
  padding-left: 3vw;
}

p {
  font-family: "Onest", serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-size: 3vw;
}

a:link {
  color: var(--dark-green);
}

a:visited {
  color: var(--white);
}

a:link.footer_ref {
  color: var(--dark-green);
}

a:visited.footer_ref {
  color: var(--dark-green);
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  h2 {
    margin-top: 2vw;
    font-size: 12vw;
  }
  p {
    font-size: 6vw;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  h2 {
    margin-top: 1vw;
    font-size: 10vw;
  }
  p {
    font-size: 4vw;
  }
}

/* Small Computer Styles */
@media only screen and (min-width: 1025px) and (max-width: 1600px) { 

}

/* Large Computer Styles */
@media only screen and (min-width: 1601px) {
  h2 {
    font-size: 78px;
  }
  p {
    font-size: 39px;
  }
}