.header_text_details {
  color: var(--white);
}

.details_section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
}

.timeline {
  padding: 0;
}

.time {
  list-style-type: none;
}

.details_p {
  color: var(--white);
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.details_title {
  color: var(--white);
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.details_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.address_holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em;
  width: 50%;
  height: 20%;
  align-self: center;
  text-decoration-thickness: 2px;
}

.address_holder:link {
  color: var(--white);
}

.address_holder:hover {
  cursor: pointer;
  filter: brightness(95%);
}

.address_p {
  color: var(--white);
  margin: 0;
  text-align: center;
}

.single_img_holder {
  width: 100%;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  .address_holder {
    width: 70%;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 501px) and (max-width: 1024px) {
  .address_holder {
    width: 60%;
  }
}

/* Small Computer Styles */
@media only screen and (min-width: 1024px) and (max-width: 1600px) { 
  .single_img_holder {
    width: 80%;
  }
}

/* Large Computer Styles */
@media only screen and (min-width: 1601px) {
  .single_img_holder {
    width: 70%;
  }
}